export default defineNuxtRouteMiddleware(() => {
  const { appUserMe } = useAppUserStore(usePinia());
  const appUserHelpers = useAppUser();
  const isAdmin = appUserHelpers.isAdmin(appUserMe);
  const isMember = appUserHelpers.isMember(appUserMe);
  const canManage = isAdmin || isMember;
  if (!canManage) {
    return abortNavigation({
      statusMessage: "Unauthorized",
      message: "You need to have at least member rights to access this page.",
    });
  }
});
